 <template>
	<section class="section-wrapper">
		<base-container>
			<base-heading :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`"/>
			<template>
				<basic-accordion>
					<accordion-item v-for="item in value.accordion" :key="item.accordion_text">
						<template slot="accordion-trigger">
							<base-font size="xs" weight="bold" color="white" marginReset>{{item.accordion_title}}</base-font>
						</template>
						<template slot="accordion-content">
							<div v-html="item.accordion_content"></div>
						</template>
					</accordion-item>
				</basic-accordion>
			</template>
			<europe-map :prefix="prefix"/>
		</base-container>
	</section>
</template>

<script>
import BasicAccordion from '../../components/molecules/accordion/BasicAccordion'
import AccordionItem from '../../components/molecules/accordion/AccordionItem'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
import EuropeMap from '../../components/molecules/EuropeMap/EuropeMap.vue'
export default {
	components: {
		BaseHeading,
		BasicAccordion,
		AccordionItem,
		EuropeMap
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0;

	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}

}
</style>
